<template>
  <div>
    <div class="wrapper-loading">
      <img src="media/logos/logo-kemendagri.png" alt="" class="mb-5" />
      <b-spinner v-if="status === ''" large variant="primary"></b-spinner>
      <span v-if="status === 'error'">Gagal login...</span>
    </div>
  </div>
</template>

<script>
import VueJwtDecode from "vue-jwt-decode";
import TokenService from "@/core/services/token.service";
// import Loader from "@/view/content/Loader.vue";
import Swal from "sweetalert2";

export default {
  name: "kominfoCallback",
  components: {},
  data() {
    return {
      resp: "",
      status: ""
    };
  },
  mounted() {
    setTimeout(() => {
      this.loginCallback();
    }, 3000);
  },
  methods: {
    loginCallback() {
      if (this.$route.query.code) {
        let redirectUri = process.env.VUE_APP_SSO_KOMINFO_CALLBACK;

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        let urlencoded = new URLSearchParams();
        urlencoded.append("code", this.$route.query.code);
        urlencoded.append("redirect_uri", redirectUri);
        urlencoded.append(
          "client_id",
          process.env.VUE_APP_SSO_KOMINFO_CLIENT_ID
        );
        urlencoded.append(
          "client_secret",
          process.env.VUE_APP_SSO_KOMINFO_CLIENT_SECRET
        );
        urlencoded.append("grant_type", "authorization_code");

        let requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
          redirect: "follow"
        };
        return fetch(
          `${process.env.VUE_APP_SSO_KOMINFO_URL}/auth/realms/${process.env.VUE_APP_SSO_KOMINFO_REALM}/protocol/openid-connect/token`,
          requestOptions
        )
          .then(r => r.json())
          .then(data => {
            console.log(data);

            if (data.access_token === undefined) {
              // return data;
              console.log("Access token not found!");
              this.$store.commit("auth/loginFailure");
              return;
            }

            const requestOptionsSidat = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + data.access_token
              }
            };
            fetch(
              `${process.env.VUE_APP_URL_LOCAL}/token/generate-token-from-sso`,
              requestOptionsSidat
            )
              .then(r => r.json())
              .then(data => {
                console.log("Data returned from generate-token-from-sso");
                console.log(data);
                if (data.access_token) {
                  TokenService.setUser(data);
                  TokenService.setToken(data.access_token);

                  // dispatch vuex
                  this.$store.commit("auth/loginSuccess", data);
                  const parsed_token = VueJwtDecode.decode(data.access_token);
                  const hasPermissions = parsed_token.role;
                  const parsed_refresh_token = VueJwtDecode.decode(
                    data.refresh_token
                  );
                  // this.$store.commit("", parsed_token);
                  this.$store.commit("token/setAttribute", hasPermissions);
                  this.$store.commit("token/setToken", data.access_token);
                  this.$store.commit("token/setParsedToken", parsed_token);
                  this.$store.commit(
                    "token/setRefreshToken",
                    data.refresh_token
                  );
                  this.$store.commit(
                    "token/setParsedRefreshToken",
                    parsed_refresh_token
                  );
                  if (parsed_token.role.id === 2) {
                    this.$store.commit("token/setIsSuperAdmin", true);
                  } else {
                    this.$store.commit("token/setIsSuperAdmin", false);
                  }

                  this.$router.push({ name: "dashboard" });
                } else {
                  this.$store.commit("auth/loginFailure");
                }

                // return data;
              })
              .catch(reason => {
                // return reason;
                console.log(reason);
              });
          })
          .catch(error => {
            console.log("error", error);
            this.status = "error";
            this.resp = error;
            Swal.fire({
              icon: "warning",
              title: error,
              confirmButtonColor: "#063A69",
              confirmButtonText: "Ok"
            });
            // return error;
          });
      } else {
        console.log("Code not found");
      }
    }
  }
};
</script>

<style scoped>
.wrapper-loading {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: fixed;
}
</style>
