import { render, staticRenderFns } from "./kominfoCallback.vue?vue&type=template&id=6603d38c&scoped=true"
import script from "./kominfoCallback.vue?vue&type=script&lang=js"
export * from "./kominfoCallback.vue?vue&type=script&lang=js"
import style0 from "./kominfoCallback.vue?vue&type=style&index=0&id=6603d38c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6603d38c",
  null
  
)

export default component.exports